import React, { useEffect } from "react";
import { EuiGlobalToastList } from "@elastic/eui";

const Notification = ({ toasts, setToasts }) => {
  useEffect(() => {
    if (toasts.length > 0) {
      const timer = setTimeout(() => {
        setToasts((prevToasts) => prevToasts.slice(1));
      }, 3000); // Tiempo de desaparición de 3 segundos

      return () => clearTimeout(timer);
    }
  }, [toasts, setToasts]);

  return (
    <EuiGlobalToastList
      toasts={toasts}
      dismissToast={() => setToasts((prevToasts) => prevToasts.slice(1))}
      toastLifeTimeMs={3000} // También asegúrate de que desaparezca después de 3 segundos
    />
  );
};

export default Notification;
